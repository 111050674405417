@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./assets//styles//animations.css');

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center-all {
}

/* Remove up and down arrows from number input */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 2px solid #d0d5dd;
  border-radius: 7px;
  padding: 10px !important;
  transform: translateY(0em);
}

input[type='checkbox']:checked {
  appearance: none;
  background-color: #446db3;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 7px;
  transform: translateY(0em);
}

input[type='checkbox']:checked::after {
  content: '';
  width: 10px;
  height: 10px;
  background-size: 100%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  clip-path: polygon(12.93% 43.73%, 6.47% 54.36%, 0% 65%, 51.28% 96.17%, 100% 16%, 78.79% 3.11%, 43% 62%);
  transform: translate(-50%, -50%);
}

/* multi-domain-input */
.multi-domain-input .css-t3ipsp-control {
  border-radius: 0.375rem;
  margin-top: 4px !important;
}

.multi-domain-input .css-13cymwt-control {
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  border-radius: 0.375rem;
  margin-top: 4px !important;
}

.multi-domain-input input {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

/* table */

.custom-table-section {
  border: none;
  border-radius: 84px;
  border-bottom: none !important;
}

.MuiTableContainer-root {
  border: 2px solid #e5e5e5;
  border-bottom: none;
  border-radius: 10px;
}

.css-10gei56 {
  border: none !important;
}

.custom-table-section .MuiPaper-root {
  box-shadow: none;
  /* border-radius: 0.5rem; */
  background: transparent;
}

.custom-table-section .MuiPaper-root .css-1tbggly {
  /* background: transparent; */
  /* border-bottom: 2px solid #e5e5e5; */
  border-top: none !important;

  /* border-radius: -8px -8px !important; */
  /* box-shadow: none;
  border-radius: 0.5rem; */
}

.custom-table-section .MuiPaper-root table thead tr {
  background: transparent;
}

.ellipsis-150 {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-select-arrow {
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,%3Csvg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11.25 0.75L6 5.25L0.75 0.75" stroke="%231C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 10px;
}

/* .MuiMenu-paper {
  overflow: hidden !important;
  border-radius: 10px;
  border: 2px solid #e5e5e5;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
} */

/* .MuiPagination-root {
  border: 2px solid red;
} */

/* MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-1uxkzex-MuiButtonBase-root-MuiIconButton-root */
/* css-1p8dotb-MuiButtonBase-root-MuiIconButton-root */
/* class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" */
/* class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" */
/* .css-1p8dotb-MuiButtonBase-root-MuiIconButton-root button svg {
  display: none !important;
} */

/* class="Mui-TableHeadCell-Content MuiBox-root css-1w86f15" */

/* .Mui-TableHeadCell-Content {

} */

/* MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root */
/* Mui-TableHeadCell-Content-Actions MuiBox-root css-epvm6 */
.Mui-TableHeadCell-Content .Mui-TableHeadCell-Content-Actions button:nth-child(1) {
  display: none !important;
}

/* String */
.truncate-text-18ch {
  display: inline-block; /* Ensures the element is treated as a block for width control */
  max-width: 18ch; /* Limit the text to 10 characters width */
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflow content */
  text-overflow: ellipsis; /* Adds ellipsis when text overflows */
  vertical-align: bottom; /* Aligns the text vertically for better aesthetics */
}

/* For Webkit browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}
/* .custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f7f7f7;
} */
.custom-scrollbar::-webkit-scrollbar-thumb {
  /* background-color: #4A5568; */
  border-radius: 4px;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  /* scrollbar-color: #4A5568 #f7f7f7; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 1535px) and (min-width: 1200px) {
  .sc-severity {
    height: 304px !important;
  }
}
